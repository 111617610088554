import { camelizeKeys } from "humps"
import { isBrowser } from "../../../services/general"
import { GATSBY_WEBSITE_URL } from "gatsby-env-variables"

export const hasSignedInUser = () => {
  if (isBrowser()) {
    const USER = Object.keys(window.sessionStorage).filter((item) =>
      item.startsWith("firebase:authUser")
    )[0]

    return USER
  }
}

export const getSignedInUser = () => {
  if (isBrowser()) {
    let fauthUser = Object.keys(window.sessionStorage).filter((item) =>
      item.startsWith("firebase:authUser")
    )[0]
    let authUser = JSON.parse(sessionStorage.getItem(fauthUser))
    let userData = camelizeKeys(JSON.parse(sessionStorage.getItem("userData")))
    let addresses = JSON.parse(sessionStorage.getItem("addresses"))

    return { authUser, userData, addresses }
  } else return {}
}

export const hasRoles = () => {
  const { userData } = getSignedInUser()

  let includesSubdomain = false

  if (userData) {
    let activeRole = userData?.roles?.find(
      (role) =>
        role?.subdomain === GATSBY_WEBSITE_URL && role?.status === "active"
    )
    if (activeRole) includesSubdomain = true
  }

  return includesSubdomain
}

export const isEmailVerified = () => {
  const { authUser } = getSignedInUser()

  if (authUser && authUser.emailVerified) {
    return true
  }
  return false
}
