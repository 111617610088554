import React, { Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import Zendesk from "react-zendesk"

import { GATSBY_ZENDESK_MH_CHAT_ID } from "gatsby-env-variables"

import SEO from "./SEO"
import Navbar from "./Navbar"
import Notification from "./Notification"
import { isBrowser } from "services/general"
import { hasSignedInUser, hasRoles } from "../../components/Auth/services/user"
// import HelpCenterBanner from "../StaticPages/HelpCenter/HelpCenterBanner"
import Footer from "./Footer"
import Container from "./Container"

import zendeskSettings from "./utils/zendeskSettings.json"

/**
 * @param {string} seoTitle string
 * @param {string} title string
 * @param {string} subtitle string
 * @param {array} path array
 * @param {object} display settings to display footer/help banner
 * @param {} children
 */

const Layout = ({
  children,
  title,
  subtitle,
  seoTitle,
  display = { footer: true, helpCenterBanner: true },
  path,
  isPrivate,
}) => {
  let pathArray = path ? path.split("/") : []

  useEffect(() => {
    // if (isPrivate && isBrowser() && !hasSignedInUser()) {
    //   navigate("/sign-in")
    // }
    if (isPrivate && isBrowser()) {
      if (hasSignedInUser() && !hasRoles()) navigate("/enrollment")
      if (!hasSignedInUser()) navigate("/verify-email")
    }
  }, [])

  return (
    <Fragment>
      <SEO title={seoTitle} />
      <Navbar path={pathArray} />
      <Notification />
      {title && (
        <Container isCentered fullhd={6}>
          <h2 className="mt-4 mb-0 has-text-primary has-text-centered">
            {title}
          </h2>
          <h4 className="mt-1 mb-2 subtitle has-text-grey is-size-6 has-text-centered">
            {subtitle}
          </h4>
        </Container>
      )}
      <main className="mb-3">
        <div className="is-fullwidth">{children}</div>
      </main>

      {/* {display.helpCenterBanner && <HelpCenterBanner />} */}
      {display.footer && <Footer />}
      {hasRoles() ? (
        <Zendesk zendeskKey={GATSBY_ZENDESK_MH_CHAT_ID} {...zendeskSettings} />
      ) : null}
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seoTitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  path: PropTypes.array,
}

export default Layout
